export const sortSectionItemsOfMenu = (menuList) => {
  const result = menuList.sort((currentItem, nextItem) =>
    currentItem.item_name.localeCompare(nextItem.item_name)
  );
  return result;
};

export const sortIngredients = (ingredientList) => {
  const result = ingredientList.sort((currentItem, nextItem) =>
    currentItem.substitute_ingredient.name.localeCompare(
      nextItem.substitute_ingredient.name
    )
  );
  return result;
};

export const sortSubstitutes = (substitutesList) => {
  const result = substitutesList.sort((currentItem, nextItem) =>
    currentItem.name.localeCompare(nextItem.name)
  );
  return result;
};

export const priorityOrderSort = (target, name) => {
  const value = name.toLowerCase();
  if (value === target) return [0, name];
  if (value.startsWith(target)) return [1, name];
  if (value.includes(target)) return [2, name];
  return [3, name];
};

export const sortIngredientsFollowKey = (keySearch, list, key) => {
  return list.sort((a, b) => {
    const itemA = priorityOrderSort(keySearch, a[key]);
    const itemB = priorityOrderSort(keySearch, b[key]);

    return itemA[0] !== itemB[0]
      ? itemA[0] - itemB[0]
      : itemA[1].toLowerCase().localeCompare(itemB[1].toLowerCase());
  });
};

const getPriority = (item, restaurantId, parentRestaurantId) => {
  if (!item?.restaurant_id) return 0;
  if (item?.restaurant_id == restaurantId) return 1;
  if (item?.restaurant_id == parentRestaurantId) return 2;
  return 3;
};

export const sortIngredientsByRestaurantId = (
  menuList,
  restaurantId,
  parentRestaurantId
) => {
  return menuList.sort((a, b) => {
    const ingredient_a = getPriority(a, restaurantId, parentRestaurantId);
    const ingredient_b = getPriority(b, restaurantId, parentRestaurantId);
    return ingredient_a - ingredient_b;
  });
};
