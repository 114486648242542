import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, InputGroup } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageUploader from "../../../components/ImageUploader";
import { ImInfo } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { Container, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { LoadingState } from "../../../utilities/constants";
import {
  fetchCategories,
  fetchFeatures,
  fetchUsers,
  fetchVenue,
  fetchStatus,
  updateVenue
} from "../../../store/venue/venueSlice";
import { VenueFormSchema } from "./VenueFormValidationSchema";
import { appConfigurations } from "../../../utilities/config";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import GoogleAddressAutoCompleteWidget from "../../../components/GoogleAddressAutoCompleteWidget";
import Tippy from "@tippyjs/react";
import LoadingComponent from "../../../components/LoadingComponent";
import {
  removeVenueImageApi,
  updateVenueImageApi
} from "../../../store/venue/venueApi";
import noDataImage from "../../../images/no-data.svg";
import useSaveFormData from "../../../hooks/useSaveFormData";

const Select = React.forwardRef(
  ({ onChange, onBlur, name, label, options }, ref) => (
    <>
      <div className="row">
        <Form.Group as={Col} className="mb-3" controlId="formBasicEmail">
          <Form.Label>{label}</Form.Label>
          <Form.Select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
          >
            {options.map((status) => (
              <option label={status.name} value={status.id} key={status.id}>
                {status.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    </>
  )
);

const Venue = () => {
  const { register, reset, handleSubmit, formState, control } = useForm({
    resolver: yupResolver(VenueFormSchema),
    defaultValues: {
      address: null,
      category: [],
      description: "",
      email: "",
      user_id: "",
      name: "",
      phone: "",
      website: "",
      status: null,
      isActive: false
    },
    mode: "onChange"
  });

  const { errors } = formState;

  const {
    categories,
    features,
    users,
    venue,
    status,
    fetchCategoriesStatus,
    fetchFeaturesStatus,
    fetchUsersStatus,
    fetchVenueStatus,
    fetchStatusStatus
  } = useSelector((state) => state.venue);
  const [categoriesData, setCategoriesData] = useState([]);
  const [userIDData, setUserIDData] = useState([]);
  const [featuresData, setFeaturesData] = useState([]);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showVenueError, setShowVenueError] = useState(false);

  const dispatch = useDispatch();

  useSaveFormData(formState);

  const { id } = useParams();
  const restaurantId = id;
  useEffect(() => {
    setLoading(true);
    if (fetchVenueStatus === LoadingState.idle) {
      dispatch(fetchVenue(restaurantId));
    }

    if (fetchCategoriesStatus === LoadingState.idle) {
      dispatch(fetchCategories());
    }
    if (fetchFeaturesStatus === LoadingState.idle) {
      dispatch(fetchFeatures());
    }
    if (fetchUsersStatus === LoadingState.idle) {
      dispatch(fetchUsers());
    }
    if (fetchStatusStatus === LoadingState.idle) {
      dispatch(fetchStatus());
    }

    if (
      fetchVenueStatus === LoadingState.succeeded &&
      fetchCategoriesStatus === LoadingState.succeeded &&
      fetchFeaturesStatus === LoadingState.succeeded &&
      fetchUsersStatus === LoadingState.succeeded &&
      fetchStatusStatus === LoadingState.succeeded
    ) {
      setLoading(false);
    }
  }, [
    dispatch,
    fetchCategoriesStatus,
    fetchFeaturesStatus,
    fetchUsersStatus,
    venue,
    fetchVenueStatus,
    fetchStatusStatus,
    restaurantId
  ]);

  useEffect(() => {
    if (fetchVenueStatus === LoadingState.succeeded) {
      reset({
        address: venue.address,
        category: venue.category,
        description: venue.description,
        email: venue.email,
        is_active: venue.is_active,
        name: venue.name,
        phone: venue.phone,
        status:
          venue.status?.id || status.find((s) => s.name === "Backlog")?.id,
        website: venue.website,
        user_id: [venue.user_id]
      });

      setImage(venue.image);
      setCategoriesData(venue.category);
      setFeaturesData(venue.features);
      setUserIDData(venue.user_id ? [venue.user_id] : []);
    }

    if (fetchVenueStatus === LoadingState.failed) {
      setLoading(false);
      setShowVenueError(true);
    }
  }, [reset, venue, fetchVenueStatus, status]);

  const updateUploadedFiles = (file) => {
    if (!file) {
      return;
    }
    setLoading(true);
    const data = {
      restaurantId: restaurantId,
      payload: { image: file }
    };
    updateVenueImageApi(data)
      .then((result) => {
        setImage(result.image);
        setLoading(false);

        toast.success("Venue image is successfully uploaded");
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed to upload the image");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const formattedData = {
        ...data,
        user_id: userIDData[0]?.id || 0,
        category: data.category.map((cat) => cat.id),
        address: data?.address
          ? `${data.address.address_line}, ${data.address.latitude}, ${data.address.longitude}`
          : null
      };
      const venueData = {
        ...formattedData,
        features: featuresData.map((feature) => feature.id)
      };

      const result = await dispatch(
        updateVenue({ restaurantId: restaurantId, venueData: venueData })
      );

      if (!result.error) {
        unwrapResult(result);
        setLoading(false);
        toast.success("Venue successfully updated");
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const removeUploadedFile = () => {
    setLoading(true);
    const payload = {
      restaurant_id: restaurantId
    };

    removeVenueImageApi(payload)
      .then((result) => {
        setImage(result.image);
        setLoading(false);
        toast.success("Venue image is successfully removed");
      })
      .catch((e) => {
        setLoading(false);
        toast.error("Failed to remove the image");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container className="ps-4 pe-4 position-relative">
      {loading && <LoadingComponent />}
      {showVenueError && !loading && (
        <div className="no-data">
          <img src={noDataImage} className="img-fluid" alt="no data" />
          <h6 v-else>No restaurant venue details found for the given ID</h6>
        </div>
      )}
      {!showVenueError && (
        <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <Row>
            <Col sm={12} className="mb-3 mt-3">
              <ImageUploader
                getUploadedContent={updateUploadedFiles}
                removeUploadedContent={removeUploadedFile}
                initialImageData={image}
                uploaderId="venue-image"
                key="venue-image"
              />
            </Col>
            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                {...register("name")}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback id="nameError" type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Address</Form.Label>
              <Controller
                control={control}
                {...register("address")}
                name="address"
                render={({ field }) => {
                  return (
                    <GoogleAddressAutoCompleteWidget
                      field={field}
                    ></GoogleAddressAutoCompleteWidget>
                  );
                }}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                {...register("description")}
                isInvalid={errors.description}
              />
              <Form.Control.Feedback id="descriptionError" type="invalid">
                {errors.description?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                name="phone"
                {...register("phone")}
                isInvalid={errors.phone}
              />
              <Form.Control.Feedback id="phoneError" type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                {...register("email")}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback id="emailError" type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Website</Form.Label>
              <Form.Control
                name="website"
                {...register("website")}
                isInvalid={errors.website}
              />
              <Form.Control.Feedback id="websiteError" type="invalid">
                {errors.website?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Category</Form.Label>
              <Controller
                control={control}
                {...register("category")}
                name="category"
                isInvalid={errors.category}
                render={({ field }) => {
                  return (
                    <Typeahead
                      id="category"
                      labelKey="name"
                      multiple={true}
                      selected={categoriesData}
                      onChange={(options) => {
                        field.onChange(options);
                        setCategoriesData(options);
                      }}
                      onFocus={field.onTouched}
                      onBlur={field.onBlur}
                      options={categories}
                    >
                      <div className="invalid-result">
                        {errors.category?.message}
                      </div>
                    </Typeahead>
                  );
                }}
              />
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Features</Form.Label>
              <div className="features-list">
                {features.map((feature, i) => (
                  <Fragment key={i}>
                    <div className="d-flex align-items-center mb-1">
                      <Form.Check
                        key={i}
                        id={i}
                        value={feature.name}
                        checked={
                          featuresData.filter(
                            (feat) => feat.name === feature.name
                          ).length > 0
                        }
                        onChange={(e) => {
                          if (
                            featuresData.filter(
                              (feat) => feat.name === feature.name
                            ).length > 0
                          ) {
                            setFeaturesData(
                              featuresData.filter(function (el) {
                                return el.name !== e.target.value;
                              })
                            );
                          } else {
                            setFeaturesData([...featuresData, features[i]]);
                          }
                        }}
                      />
                      <span className="ml-3 mr-3">{feature.name}</span>
                      <Tippy
                        placement="right"
                        content={feature.description}
                        interactive={true}
                        maxWidth="400px"
                      >
                        <span className="info-icon">
                          <ImInfo />
                        </span>
                      </Tippy>
                    </div>
                  </Fragment>
                ))}
                <Form.Control.Feedback id="featuresError" type="invalid">
                  {errors.features?.message}
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Select
                label="status"
                options={status}
                defaultValue={status.map((status) => status.name === "Backlog")}
                {...register("status")}
                isInvalid={errors.status}
              />
              <div id="statusError" className="invalid-result">
                {errors.status?.message}
              </div>
            </Form.Group>
            <Col sm={12} lg={8} className="mb-3">
              <Form.Label>User Email</Form.Label>
              <InputGroup>
                <Controller
                  control={control}
                  {...register("user_id")}
                  name="user_id"
                  isInvalid={errors.user_id}
                  render={({ field }) => {
                    return (
                      <Typeahead
                        id="user_id"
                        labelKey="email"
                        multiple={false}
                        onChange={(option) => {
                          field.onChange(option);
                          setUserIDData(option);
                        }}
                        selected={userIDData}
                        onFocus={field.onTouched}
                        onBlur={field.onBlur}
                        options={users}
                      >
                        <div id="user_id_error" className="invalid-result">
                          {errors.user_id?.message}
                        </div>
                      </Typeahead>
                    );
                  }}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  href={
                    appConfigurations.API_BASE_URL +
                    appConfigurations.ADMIN_PORTAL_USER_ADD_URL
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlinePlus />
                </Button>
              </InputGroup>
            </Col>

            <Form.Group as={Col} sm={12} lg={8} className="mb-3">
              <Form.Label>Is Active</Form.Label>
              <Controller
                name="is_active"
                {...register("is_active")}
                control={control}
                isInvalid={errors.is_active}
                render={({ field }, ref) => {
                  const parsed = field.value === true ? true : false;
                  const format = () =>
                    field.onChange(field.value === true ? false : true);
                  return (
                    <>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          inputRef={ref}
                          id="Yes"
                          onChange={format}
                          value={true}
                          checked={parsed === true}
                          defaultChecked={parsed === true}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          inputRef={ref}
                          id="No"
                          onChange={format}
                          value={false}
                          checked={parsed === false}
                          defaultChecked={parsed === false}
                        />
                      </div>
                    </>
                  );
                }}
              />
            </Form.Group>

            <Col sm={12} lg={8}>
              <Button
                className="mb-4 mt-4"
                type="submit"
                variant="primary"
                disabled={loading}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default Venue;
