import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, InputGroup, Container } from "react-bootstrap";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { HiOutlinePlusSm } from "react-icons/hi";
import { Controller, set, useFieldArray } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import Substitute from "./Substitute";
import { useSelector } from "react-redux";
import {
  sortIngredientsByRestaurantId,
  sortIngredientsFollowKey
} from "../../../utilities/index";
import { get } from "../../../utilities/apiHelper";
import IngredientInfoModal from "../../../components/IngredientInfoModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { arrayMoveImmutable } from "array-move";
import { sortOrderingOfIngredients } from "../../../store/menu/menuApi";
import { toast } from "react-toastify";
import actionImage from "../../../action.svg";
import OverlayComponent from "../../../components/OverlayComponent";
import { maxBy, orderBy } from "lodash";
import { INGREDIENT_CAN_BE_REMOVED } from "../../../utilities/constants";

export default function Ingredients({
  ingredients,
  control,
  register,
  errors,
  menuIndex,
  getValues,
  itemIngredients,
  setError,
  updateMenuItemOnIngredientsChange,
  ingredientsKey,
  restaurantId,
  ableDragAndDrop
}) {
  const { venue } = useSelector((state) => state.venue);
  const [isShowIngredientInfo, setIsShowIngredientInfo] = useState(false);
  const [ingredientInfo, setIngredientInfo] = useState({
    title: "",
    description: ""
  });
  const [loadingIngredientInfo, setLoadingIngredientInfo] = useState();
  const [substituteKey, setSubstituteKey] = useState(new Date().toISOString());
  const [selectedIngredients, setSelectedIngredients] =
    useState(itemIngredients);
  const [selectedSubstitutes, setSelectedSubstitutes] = useState(
    itemIngredients?.map((ing) => ing?.substitutes ?? [])
  );

  const { fields, remove, update, prepend, insert } = useFieldArray({
    name: `section_items[${menuIndex}].item_ingredients`,
    control,
    keyName: "key"
  });

  const [listMenu, setListMenu] = useState([]);

  //Add input for new ingredient
  const addNewIngredientInput = () => {
    const values = getValues();
    const orderMax = maxBy(
      values.section_items[menuIndex]?.item_ingredients,
      "order"
    )?.order;
    if (fields.length === 0 || fields[fields.length - 1].ingredient_id) {
      prepend({
        substitute_ingredient: {},
        substitutes: [],
        order: (orderMax ?? 0) + 1
      });
    }
  };

  const getAllergenByIngredientId = async (id) => {
    try {
      const result = await get(
        `/menu/v10/get_allergens_by_ingredient/?id=${id}`,
        true
      );

      if (!result.data) {
        alert(result.data.message);
      }

      return result?.data;
    } catch (error) {
      alert(error.message);
    }
  };

  //Add new ingredient
  const addNewIngredient = (index) => {
    const values = getValues();
    const appendedIngredient =
      values.section_items[menuIndex].item_ingredients[index];
    if (
      !appendedIngredient ||
      (appendedIngredient.substitute_ingredient &&
        appendedIngredient.substitute_ingredient.length === 0)
    ) {
      setError(
        `section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`,
        {
          type: "focus",
          message: "Ingredient is a required field"
        },
        { shouldFocus: true }
      );
      return;
    }
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;

    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id && ing.substitute_ingredient?.length !== 0
    );
    ingredientsToBeSaved.unshift(appendedIngredient);
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
  };

  //Function to remove the new ingredient inputs which are not actually saved in the database
  const cancelNewIngredient = (index) => {
    remove(index);
  };

  //Function to remove ingredient from menu item
  const handleRemoveIngredient = (index) => {
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;
    ingredientList.splice(index, 1);
    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id
    );
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
  };

  //Function to extract allergens of the menu item from backend response
  const ingredientAllergenList = (allergens) => {
    return allergens
      ?.filter((i) =>
        ["Allergens", "FODMAP Diets", "Diets"].includes(
          i.allergens_category?.category_name
        )
      )
      .map((a) => a.allergens_name);
  };

  //Add input for new substitute
  const addNewSubstituteInput = (ingredientIndex) => {
    const values = getValues();
    const selectedIngredient =
      values.section_items[menuIndex].item_ingredients[ingredientIndex];
    const substitutes = selectedIngredient?.substitutes
      ? selectedIngredient?.substitutes
      : [];
    if (
      substitutes.length === 0 ||
      Object.keys(substitutes[substitutes.length - 1]).length !== 0
    ) {
      substitutes.push({
        order: (maxBy(substitutes, "order")?.order ?? 0) + 1
      });
      update(ingredientIndex, {
        ...selectedIngredient,
        substitutes: substitutes
      });

      setSubstituteKey(new Date().toISOString());
    }
  };

  //Function to handle menu item update on ingredients update
  const handleIngredientOnChangeEvent = (index, options, ingredient) => {
    const currentIngredients = selectedIngredients;
    if (ingredient.ingredient_id) {
      currentIngredients[index].substitute_ingredient = options[0];
    } else {
      currentIngredients.splice(index, 0, {
        substitute_ingredient: options[0]
      });
    }

    setSelectedIngredients(currentIngredients);
    if (ingredient.ingredient_id && options.length !== 0) {
      updateMenuItemOnIngredientsChange(
        menuIndex,
        getValues().section_items?.[menuIndex].item_ingredients?.filter(
          (ing) => ing.ingredient_id
        )
      );
    } else if (index === 0) addNewIngredient(index);
  };

  //Function change reorder list menu
  const handleSearch = (query, index) => {
    const keySearch = query.toLowerCase();
    const menus = [...filterOption(ingredients, index)].filter(
      (menu) =>
        (!menu.restaurant_id ||
          menu.restaurant_id == restaurantId ||
          menu.restaurant_id == venue?.parent_restaurant ||
          (venue?.list_child_restaurant?.length &&
            venue?.list_child_restaurant.includes(menu.restaurant_id))) &&
        (menu?.display_name?.toLowerCase().includes(keySearch) ||
          menu?.name?.toLowerCase().includes(keySearch))
    );

    const formatList = sortIngredientsByRestaurantId(
      menus,
      restaurantId,
      venue?.parent_restaurant
    );

    const mainItems = formatList.filter((one) => !!one.display_name);
    const other = formatList.filter((one) => !one.display_name);

    const newList = [
      ...sortIngredientsFollowKey(keySearch, mainItems, "display_name"),
      ...sortIngredientsFollowKey(keySearch, other, "name")
    ];

    const idxIngredientCanBeRemove = newList.findIndex(
      (one) => one.name === INGREDIENT_CAN_BE_REMOVED
    );
    if (idxIngredientCanBeRemove !== -1) {
      setListMenu(arrayMoveImmutable(newList, idxIngredientCanBeRemove, 0));
    } else {
      setListMenu(newList);
    }
  };

  const convertIgredientName = (ingredient) => {
    if (ingredient.display_name) {
      return `${ingredient.display_name} (${ingredient.name})`;
    }

    return ingredient.name;
  };

  const filterOption = (list, index) => {
    return [...list].filter((ing) => {
      return (
        ![...fields.map((si) => si?.substitute_ingredient?.id)]
          .filter(
            (id) =>
              !(
                fields[index]?.substitute_ingredient &&
                fields[index]?.substitute_ingredient?.id === id
              )
          )
          .includes(ing?.id) &&
        !selectedSubstitutes[index]?.map((sub) => sub.id).includes(ing?.id)
      );
    });
  };

  const viewMoreIngredient = async (e, result) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      setLoadingIngredientInfo(true);
      setIsShowIngredientInfo(true);
      const title = result.display_name ?? result.name;
      const res = await getAllergenByIngredientId(result.id);
      if (res.length) {
        setIngredientInfo({
          description: res.join(", "),
          title
        });
      } else {
        setIngredientInfo({ title });
      }
      setTimeout(() => {
        setLoadingIngredientInfo(false);
      }, 500);
    } catch (e) {
      alert(e.message);
      setIsShowIngredientInfo(false);
    }
  };

  const handleChangePosition = async (startIndex, endIndex) => {
    try {
      const newList = arrayMoveImmutable(fields, startIndex, endIndex);
      const payload = {
        item_id: getValues().section_items?.[menuIndex]?.id,
        ingredients: newList.map(({ ingredient_id }, index) => ({
          id: ingredient_id,
          order: index + 1
        }))
      };
      remove(startIndex);
      insert(endIndex, fields[startIndex]);
      await sortOrderingOfIngredients(payload);
      setSelectedIngredients([
        ...newList.map((one, index) => ({ ...one, order: index + 1 }))
      ]);
      toast.success("Menu item updated successfully");
    } catch (error) {
      toast.error("Failed to update the Menu item");
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex !== endIndex) {
      handleChangePosition(startIndex, endIndex);
    }
  };

  const checkRestaurantRelation = (restaurant_id, is_delete = false) => {
    if (is_delete) return true;
    if (!restaurant_id) return false;
    return (
      restaurant_id != restaurantId &&
      venue?.parent_restaurant != restaurant_id &&
      !venue?.list_child_restaurant?.includes(restaurant_id)
    );
  };

  return (
    <>
      <Form.Group className="mt-3">
        <Form.Label>Ingredients</Form.Label>
        <Container>
          <Row className="add-new-section my-2">
            <Col sm={4} className="d-flex align-items-center p-0">
              <hr />
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <Button
                type="button"
                variant="outline-primary"
                className="add-new-button"
                onClick={() => addNewIngredientInput()}
              >
                <HiOutlinePlusSm />
                Ingredient
              </Button>
            </Col>
            <Col sm={4} className="d-flex align-items-center p-0">
              <hr />
            </Col>
          </Row>
        </Container>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields?.length > 0 &&
                  fields.map((ingredient, index) => (
                    <Draggable
                      key={ingredient.key}
                      isDragDisabled={!ableDragAndDrop}
                      draggableId={ingredient.key}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="mb-3">
                            <Col sm={10} className="w-100">
                              <Form.Group>
                                <div className="ingredient-box">
                                  <Controller
                                    control={control}
                                    name={`section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`}
                                    {...register(
                                      `section_items[${menuIndex}].item_ingredients[${index}].substitute_ingredient`
                                    )}
                                    rules={{ required: true }}
                                    isInvalid={
                                      !!errors.section_items?.[menuIndex]
                                        ?.item_ingredients?.[index] &&
                                      errors.section_items?.[menuIndex]
                                        ?.item_ingredients?.[index]
                                        ?.substitute_ingredient
                                    }
                                    render={({
                                      field,
                                      fieldState: {
                                        error,
                                        isDirty,
                                        isInvalid,
                                        isTouched
                                      }
                                    }) => {
                                      const { display_name, name, restaurant, is_delete } =
                                        field?.value;
                                      const isError =
                                        checkRestaurantRelation(restaurant, is_delete);
                                      return (
                                        <div
                                          className={`input-box ${
                                            isError && "error-box"
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <InputGroup className="position-relative">
                                              <Typeahead
                                                key={ingredientsKey}
                                                id={`section_items[${menuIndex}].item_ingredients[${index}].id`}
                                                placeholder="Select Ingredient"
                                                labelKey={({
                                                  display_name,
                                                  name
                                                }) => {
                                                  return !!display_name
                                                    ? `${display_name} (${name})`
                                                    : name;
                                                }}
                                                multiple={false}
                                                className="ingredient-input-field"
                                                onChange={(options) => {
                                                  field.onChange(options);
                                                  handleIngredientOnChangeEvent(
                                                    index,
                                                    options,
                                                    ingredient
                                                  );
                                                }}
                                                renderMenu={(
                                                  results,
                                                  menuProps
                                                ) => (
                                                  <Menu
                                                    {...menuProps}
                                                    className="ingredient-menu-items"
                                                  >
                                                    {results.map(
                                                      (result, index) => {
                                                        return (
                                                          <MenuItem
                                                            style={{
                                                              position:
                                                                "relative",
                                                              padding:
                                                                "6px 20px",
                                                              color:
                                                                result.is_official
                                                                  ? "#0747A6"
                                                                  : "black",
                                                              fontWeight:
                                                                result.is_official
                                                                  ? "bold"
                                                                  : "400"
                                                            }}
                                                            option={result}
                                                            position={index}
                                                          >
                                                            {convertIgredientName(
                                                              result
                                                            )}
                                                            <span
                                                              className="ingredient-more-info"
                                                              onClick={(e) =>
                                                                viewMoreIngredient(
                                                                  e,
                                                                  result
                                                                )
                                                              }
                                                            >
                                                              &#9432;
                                                            </span>
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Menu>
                                                )}
                                                defaultInputValue={
                                                  display_name ?? name
                                                }
                                                options={listMenu}
                                                onFocus={(event) =>
                                                  handleSearch(
                                                    event.target.value,
                                                    index
                                                  )
                                                }
                                                onInputChange={(query) =>
                                                  handleSearch(query, index)
                                                }
                                              />
                                              <div className="edit-icon">
                                                <BiEditAlt />
                                              </div>
                                            </InputGroup>
                                            <Button
                                              className="d-flex align-items-center ms-2"
                                              variant="outline-danger"
                                              size="sm"
                                              onClick={() => {
                                                ingredient.ingredient_id
                                                  ? handleRemoveIngredient(
                                                      index
                                                    )
                                                  : cancelNewIngredient(index);
                                              }}
                                            >
                                              <RiDeleteBinLine className="me-2" />
                                              Delete
                                            </Button>
                                            {ingredient.ingredient_id && (
                                              <Button
                                                className="d-flex align-items-center ms-2"
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() =>
                                                  addNewSubstituteInput(index)
                                                }
                                              >
                                                <HiOutlinePlusSm className="me-2" />
                                                Substitutes
                                              </Button>
                                            )}
                                            {ableDragAndDrop && (
                                              <OverlayComponent
                                                index={index}
                                                list={fields}
                                                moveToTop={() =>
                                                  handleChangePosition(index, 0)
                                                }
                                                moveToBottom={() =>
                                                  handleChangePosition(
                                                    index,
                                                    fields.length - 1
                                                  )
                                                }
                                              >
                                                <img
                                                  src={actionImage}
                                                  {...provided.dragHandleProps}
                                                  className="action_drag ms-2"
                                                  alt="action"
                                                />
                                              </OverlayComponent>
                                            )}
                                          </div>
                                          {(isInvalid ||
                                            isTouched ||
                                            isDirty ||
                                            error) && (
                                            <div className="invalid-custom-feedback">
                                              {
                                                errors?.section_items?.[
                                                  menuIndex
                                                ]?.item_ingredients?.[index]
                                                  ?.substitute_ingredient
                                                  ?.message
                                              }
                                            </div>
                                          )}
                                          <div className="ingredients-allergens">
                                            {ingredient?.substitute_ingredient
                                              ?.allergens && (
                                              <p>
                                                {ingredientAllergenList(
                                                  ingredient
                                                    ?.substitute_ingredient
                                                    ?.allergens
                                                ).join(", ")}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </Form.Group>
                              <Substitute
                                restaurantId={restaurantId}
                                getValues={getValues}
                                control={control}
                                register={register}
                                errors={errors}
                                substitutes={ingredient.substitutes}
                                ingredients={ingredients}
                                selectedIngredients={selectedIngredients}
                                menuIndex={menuIndex}
                                ingredientIndex={index}
                                updateMenuItemOnIngredientsChange={
                                  updateMenuItemOnIngredientsChange
                                }
                                setError={setError}
                                key={substituteKey}
                                SelectedSubstitutesToCheckForIngredient={
                                  setSelectedSubstitutes
                                }
                                ableDragAndDrop={ableDragAndDrop}
                              />
                            </Col>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form.Group>
      <IngredientInfoModal
        isOpen={isShowIngredientInfo}
        isLoading={loadingIngredientInfo}
        emptyLabel="No Allergens"
        title={`${ingredientInfo.title} - Allergens`}
        description={ingredientInfo.description}
        onClose={() => setIsShowIngredientInfo(false)}
      />
    </>
  );
}
