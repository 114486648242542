import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const IngredientInfoModal = ({
  title,
  description,
  emptyLabel,
  isOpen,
  onClose,
  isLoading
}) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: 10,
              marginBottom: 10
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : description ? (
          description
        ) : (
          emptyLabel
        )}
      </Modal.Body>
    </Modal>
  );
};

export default IngredientInfoModal;
