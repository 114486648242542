import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  Accordion,
  Button,
  useAccordionButton,
  AccordionContext,
  Badge,
  Container
} from "react-bootstrap";
import { maxBy, orderBy, sortBy, uniqBy } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiOutlinePlusSm } from "react-icons/hi";
import { MdOutlineSaveAlt } from "react-icons/md";
import ImageUploader from "../../../components/ImageUploader";
import Ingredients from "./Ingredients";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiDuplicate } from "react-icons/bi";
import {
  addNewMenuItemApi,
  removeMenuItemApi,
  removeMenuItemImageApi,
  sortOrderingOfMenuItems,
  updateMenuItemApi,
  updateMenuItemImageApi,
  verifyAnMenuItemInSectionMeApi
} from "../../../store/menu/menuApi";
import { useParams } from "react-router-dom";
import ConfirmAction from "../../../components/ConfirmAction";
import { toast } from "react-toastify";
import { MenuFormSchema } from "./MenuFormValidationSchema";
import { appConfigurations } from "../../../utilities/config";
import { isEmpty } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { arrayMoveImmutable } from "array-move";
import actionImage from "../../../action.svg";
import OverlayComponent from "../../../components/OverlayComponent";

export default function MenuItems({
  sectionIndex,
  sections,
  ingredients,
  updateMenuItemSection,
  section,
  updateSectionOnMenuItemAdditionAndDelete,
  sectionsFieldsRef,
  activeKey,
  saveActiveKey,
  ableDragAndDrop
}) {
  const { id } = useParams();
  const restaurantId = id;
  const sectionItems = orderBy(
    section?.section_items?.map(({ item_ingredients, ...rest }) => {
      return {
        ...rest,
        item_ingredients: orderBy(item_ingredients, ["order"])
      };
    }),
    ["order"]
  );

  const [menuItems, setMenuItems] = useState(sectionItems);
  const [confirmActionVisibility, setConfirmActionVisibility] = useState(false);
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(null);
  const [oldValues, setOldValues] = useState(menuItems);

  const [ingredientsKey, setIngredientsKey] = useState(
    new Date().toISOString()
  );

  const {
    register,
    control,
    reset,
    getValues,
    setValue,
    setError,
    formState: { errors, isValid },
    clearErrors
  } = useForm({
    resolver: yupResolver(MenuFormSchema),
    defaultValues: {
      section_items: sectionItems
    },
    mode: "onChange"
  });

  const { fields, append, update, remove, insert } = useFieldArray({
    name: `section_items`,
    control,
    keyName: "key"
  });

  //Add input for new menu item
  const addNewMenuItemInput = () => {
    append({
      item_section: section.section_id,
      item_name: "",
      description: "",
      price: 0.0,
      item_ingredients: []
    });
  };

  useEffect(() => {
    if (sectionIndex && fields && sectionsFieldsRef.current.length) {
      sectionsFieldsRef.current[sectionIndex].section_items = fields;
    }
  }, [fields, sectionsFieldsRef, sectionIndex]);

  //Add new menu item
  const addNewMenuItem = (index) => {
    const values = getValues();
    const appendedMenuItem = values.section_items[index];
    if (
      !appendedMenuItem.item_name ||
      (appendedMenuItem.item_name &&
        appendedMenuItem.item_name?.toString().trim().length === 0)
    ) {
      setError(
        `section_items[${index}].item_name`,
        {
          type: "focus",
          message: "Menu Item Name is a required field"
        },
        { shouldFocus: true }
      );
      return;
    }
    const data = {
      ...appendedMenuItem,
      sold_out: false,
      order: (maxBy(values.section_items, "order")?.order ?? 0) + 1
    };
    const menuItemData = {
      payload: data,
      restaurant_id: restaurantId
    };

    addNewMenuItemApi(menuItemData)
      .then((result) => {
        const items = [...menuItems];
        items.push({ ...result, item_id: result.id, section_items: [] });
        setMenuItems(items);
        reset({
          section_items: items
        });
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, items);
        toast.success("A new menu item is created successfully");
      })
      .catch((e) => {
        toast.error("Failed to create a New menu item");
      });
  };

  //Function to update the file upload
  const updateUploadedFiles = (file, menuIndex) => {
    const menuItem = { ...fields[menuIndex] };
    menuItem.image = file;
    if (!menuItem.image) {
      return;
    }

    const data = {
      menu_item_id: menuItem.item_id,
      payload: { image: file }
    };

    updateMenuItemImageApi(data)
      .then((result) => {
        let items = [...menuItems];
        items[menuIndex] = {
          ...result,
          item_id: result.id,
          item_ingredients: result.item_ingredients?.map((ingredient) => ({
            ...ingredient,
            ingredient_id: ingredient.id
          }))
        };
        setMenuItems(items);
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, items);
        update(menuIndex, items[menuIndex]);
        toast.success("Menu image uploaded successfully");
      })
      .catch((e) => {
        toast.error("Failed to upload the image");
      });
  };

  //Function to handle menu item change from one section to another section
  const handleMenuItemSectionChange = (itemId, event) => {
    updateMenuItemSection(sectionIndex, itemId, event?.target?.value);
  };

  const removeUploadedFile = (item, menuIndex) => {
    const payload = {
      menu_id: item.item_id
    };

    removeMenuItemImageApi(payload)
      .then((result) => {
        let items = [...menuItems];
        items[menuIndex] = {
          ...result,
          item_id: result.id,
          item_ingredients: result.item_ingredients?.map((ingredient) => ({
            ...ingredient,
            ingredient_id: ingredient.id
          }))
        };
        setMenuItems(items);
        update(menuIndex, items[menuIndex]);
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, items);
        toast.success("Menu image is successfully removed");
      })
      .catch((e) => {
        toast.error("Failed to remove the image");
      });
  };

  //Function to remove the new menu item inputs which are not actually saved in the database
  const cancelNewMenuItemCreation = (index) => {
    remove(index);
  };

  //Function to remove menu item from section
  const handleRemoveMenuItem = (index) => {
    const values = getValues();
    const item_id = values.section_items[index].item_id;
    removeMenuItemApi(item_id)
      .then((result) => {
        if (result === 204) {
          handleConfirmActionVisibility(false);
          const items = fields;
          items.splice(index, 1);

          // Update order
          const newItems = items.map((item, index) => ({
            ...item,
            order: index + 1
          }));
          setMenuItems(newItems);
          reset({
            section_items: newItems
          });
          if (newItems.length) {
            sortOrderingOfMenuItems({
              restaurant_id: restaurantId,
              items: newItems.map(({ item_id, order }) => ({
                id: item_id,
                order
              }))
            });
          }
          //
          updateSectionOnMenuItemAdditionAndDelete(sectionIndex, newItems);
          toast.success("Menu item removed successfully");
        }
      })
      .catch((e) => {
        toast.error("Failed to remove Menu item");
      });
  };

  const handleConfirmActionVisibility = (state, index) => {
    setSelectedMenuItemIndex(index);
    setConfirmActionVisibility(state);
  };

  const duplicateMenuItem = (index) => {
    const values = getValues();
    const duplicateMenuItemData = values.section_items?.[index];
    const menuItemData = {
      payload: {
        ...duplicateMenuItemData,
        item_name: `${duplicateMenuItemData.item_name} - Copy`,
        ingredients: duplicateMenuItemData.item_ingredients.map((ing) => ({
          id: ing.substitute_ingredient.id,
          substitutes: ing?.substitutes?.map((sub) => sub.id) || []
        })),
        order: (maxBy(values.section_items, "order")?.order ?? 0) + 1
      },
      restaurant_id: restaurantId
    };
    addNewMenuItemApi(menuItemData)
      .then((result) => {
        const items = [...menuItems];
        items.push({
          ...duplicateMenuItemData,
          item_name: `${duplicateMenuItemData.item_name} - Copy`,
          item_id: result.id,
          order: result.order
        });
        setMenuItems(items);
        reset({
          section_items: items
        });
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, items);
        toast.success("A new menu item is duplicate successfully");
      })
      .catch((e) => {
        toast.error("Failed to duplicate a menu item");
      });
  };

  //Function to update menu item in instance of text fields update
  const updateMenuItem = (index) => {
    const values = getValues();
    let currentMenuItemData = values.section_items?.[index];

    if (
      errors &&
      errors?.section_items &&
      errors?.section_items[index] &&
      Object.keys(errors?.section_items[index])?.length > 0
    ) {
      Object.keys(errors?.section_items[index]).forEach((key) => {
        currentMenuItemData[key] = oldValues[index][key];
      });
    }

    const formattedCurrentMenuItem = {
      ...currentMenuItemData,
      price: currentMenuItemData.price ? currentMenuItemData.price : null,
      item_ingredients: currentMenuItemData.item_ingredients
        ?.filter((ing) => !isEmpty(ing.substitute_ingredient))
        .map((ingredient) => {
          return {
            id:
              ingredient?.substitute_ingredient?.id ??
              ingredient?.substitute_ingredient[0]?.id,
            substitutes: ingredient?.substitutes
              ? ingredient?.substitutes?.map((substitute) => substitute.id)
              : []
          };
        })
    };

    const payload = {
      item_name: formattedCurrentMenuItem.item_name,
      item_section: formattedCurrentMenuItem.item_section,
      ingredients: formattedCurrentMenuItem.item_ingredients,
      sold_out: formattedCurrentMenuItem.sold_out,
      description: formattedCurrentMenuItem.description,
      price: formattedCurrentMenuItem.price
    };

    const data = {
      menu_item_id: currentMenuItemData.item_id,
      payload: payload
    };

    updateMenuItemApi(data)
      .then((result) => {
        update(index, { ...result, item_id: result.id });
        const values = oldValues;
        values[index] = { ...result, item_id: result.id };
        setMenuItems(values);
        setOldValues(values);
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, values);
        toast.success("Menu item updated successfully");
      })
      .catch((e) => {
        toast.error("Failed to update the Menu item");
      });
  };

  // Function to update menu item when change in ingredients occur
  const updateMenuItemOnIngredientsChange = (
    menuIndex,
    ingredientList,
    isHideToast = false
  ) => {
    const values = getValues();
    const currentMenuItemData = values.section_items?.[menuIndex];
    const formattedCurrentMenuItem = {
      ...currentMenuItemData,
      price: Number(currentMenuItemData.price),
      item_ingredients: [...ingredientList]
        ?.filter((ing) => !isEmpty(ing.substitute_ingredient))
        .map((ingredient) => {
          return {
            id:
              ingredient?.substitute_ingredient?.id ??
              ingredient?.substitute_ingredient[0]?.id,
            substitutes: ingredient?.substitutes
              ? sortBy(ingredient.substitutes, "order").map(
                  (substitute) => substitute?.id ?? substitute[0]?.id
                )
              : [],
            order: ingredient?.order
          };
        })
    };
    const payload = {
      item_name: formattedCurrentMenuItem.item_name,
      item_section: formattedCurrentMenuItem.item_section,
      ingredients: formattedCurrentMenuItem.item_ingredients,
      sold_out: formattedCurrentMenuItem.sold_out,
      description: formattedCurrentMenuItem.description,
      price: formattedCurrentMenuItem.price
    };

    const data = {
      menu_item_id: currentMenuItemData.item_id,
      payload: payload
    };

    updateMenuItemApi(data)
      .then((result) => {
        update(menuIndex, {
          ...result,
          item_id: result.id,
          item_ingredients: result.item_ingredients
        });

        let items = [...menuItems];
        items[menuIndex] = {
          ...result,
          item_id: result.id,
          item_ingredients: result.item_ingredients
        };
        setMenuItems(items);
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, items);
        reset({ section_items: items });
        setIngredientsKey(new Date().toISOString());
        if (!isHideToast) toast.success("Menu item updated successfully");
      })
      .catch((e) => {
        toast.error("Failed to update the Menu item");
      });
  };

  //Customize function to toggle the menu item accordion
  const CustomMenuItemToggle = ({ children, menuItemId, index }) => {
    const decoratedOnClick = () => {
      saveActiveKey(activeKey || activeKey === 0 ? undefined : index);
    };

    const isCurrentEventKey = index === activeKey;

    return (
      <>
        {menuItemId && (
          <button
            type="button"
            aria-expanded={isCurrentEventKey}
            className={`accordion-button me-3 ${
              !isCurrentEventKey ? "collapsed" : ""
            }`}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        )}
      </>
    );
  };

  //Function to compare old and new values of the fields to avoid unnecessary update api calling
  const compareFieldValues = (oldValue, newValue) => {
    if (oldValue !== newValue) {
      return true;
    }
  };

  const validate = function (e) {
    const t = e.target.value;
    e.target.value =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
        : t;
  };

  //Function to update menu item onBlur event of the fields
  const handleFieldOnBlurEvent = (menuIndex, value, fieldName) => {
    const fieldOldValue = oldValues[menuIndex][fieldName];
    if (compareFieldValues(fieldOldValue, value)) {
      if (
        errors &&
        errors?.section_items &&
        errors?.section_items[menuIndex] &&
        errors?.section_items[menuIndex]?.[fieldName]
      ) {
        return;
      }

      updateMenuItem(menuIndex);
    }
  };

  //Function to extract the allergens and diets related to ingredients come from backend response
  const ingredientAllergenDietList = (item) => {
    const allergensDiets = item.allergens;
    const allergens = allergensDiets?.filter(
      (i) => i.allergens_category?.category_name === "Allergens"
    );
    const diets = allergensDiets.filter(
      (i) =>
        i.allergens_category?.category_name === "FODMAP Diets" ||
        i.allergens_category?.category_name === "Diets"
    );

    const ingredientAllergens = allergens.map((a) => a.allergens_name);
    const ingredientDiets = diets.map((a) => a.allergens_name);
    const subItem = ingredientAllergenDietListOfSub(item.item_ingredients);
    if (subItem.allergens && subItem.allergens.length) {
      subItem.allergens.forEach((allergen) => {
        ingredientAllergens.push(allergen);
      });
    }

    if (subItem.diets && subItem.diets.length) {
      subItem.diets.forEach((diet) => {
        ingredientDiets.push(diet);
      });
    }

    return {
      allergens: uniqBy(ingredientAllergens),
      diets: uniqBy(ingredientDiets)
    };
  };

  //Function to extract the allergens and diets related to ingredients come from backend response
  const ingredientAllergenDietListOfSub = (items) => {
    let ingredientAllergens = [];
    let ingredientDiets = [];
    items.forEach((item) => {
      const allergensDiets = item.substitutes;
      const allergens = [];
      const diets = [];
      allergensDiets?.forEach((allergensDiet) => {
        allergensDiet?.allergens?.forEach((i) => {
          if (i.allergens_category.category_name === "Allergens") {
            allergens.push(i.allergens_name);
          } else if (
            ["FODMAP Diets", "Diets"].includes(
              i.allergens_category.category_name
            )
          ) {
            diets.push(i.allergens_name);
          }
        });
      });

      ingredientAllergens = ingredientAllergens.concat(allergens);
      ingredientDiets = ingredientDiets.concat(diets);
    });

    return {
      allergens: ingredientAllergens,
      diets: ingredientDiets
    };
  };

  useEffect(() => {
    setOldValues(menuItems);
  }, [menuItems]);

  //Function to veryfy menu item in section
  const verifyItem = (menuIndex) => {
    menuItems[menuIndex].is_verified = !menuItems[menuIndex].is_verified;
    const values = menuItems;
    update(menuIndex, values[menuIndex]);
    verifyAnMenuItemInSectionMeApi(
      menuItems[menuIndex].id,
      menuItems[menuIndex].is_verified
    )
      .then((result) => {
        updateSectionOnMenuItemAdditionAndDelete(sectionIndex, values);
        toast.success("Menu item updated successfully");
      })
      .catch((e) => {
        menuItems[menuIndex].is_verified = !menuItems[menuIndex].is_verified;
        update(menuIndex, values[menuIndex]);
        toast.error("Failed to update the Menu item");
      });
  };

  const handleChangePosition = async (startIndex, endIndex) => {
    try {
      const newList = arrayMoveImmutable(fields, startIndex, endIndex);
      const payload = {
        restaurant_id: restaurantId,
        items: newList.map(({ item_id }, index) => ({
          id: item_id,
          order: index + 1
        }))
      };
      remove(startIndex);
      insert(endIndex, fields[startIndex]);
      await sortOrderingOfMenuItems(payload);
      setMenuItems([
        ...newList.map((one, index) => ({ ...one, order: index + 1 }))
      ]);
      toast.success("Menu item updated successfully");
    } catch (error) {
      reset({ section_items: fields });
      toast.error("Failed to update the Menu item");
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex !== endIndex) {
      handleChangePosition(startIndex, endIndex);
    }
  };

  return (
    <>
      {fields && (
        <Accordion className="accordion-secondary">
          <div className="card-wrapper">
            <Form>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map((item, index) => (
                        <Draggable
                          key={item.key}
                          draggableId={item.key}
                          isDragDisabled={!ableDragAndDrop}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="position-relative ps-3">
                                <hr className="tree-line" />
                                <Card className="mb-2">
                                  <Card.Header>
                                    <Row>
                                      <Col className="d-flex align-items-center">
                                        <CustomMenuItemToggle
                                          eventKey={index}
                                          menuItemId={item.id}
                                          index={index}
                                        />
                                        <Form.Group className="w-100">
                                          <Form.Control
                                            className="w-100"
                                            name={`section_items[${index}].item_name`}
                                            {...register(
                                              `section_items[${index}].item_name`
                                            )}
                                            isInvalid={
                                              !!errors?.section_items &&
                                              errors?.section_items?.[index]
                                                ?.item_name
                                            }
                                            onBlur={(e) => {
                                              if (item.item_id) {
                                                handleFieldOnBlurEvent(
                                                  index,
                                                  e?.target?.value,
                                                  "item_name"
                                                );
                                              }
                                            }}
                                          />
                                          <Form.Control.Feedback
                                            id="itemNameError"
                                            type="invalid"
                                          >
                                            {!!errors?.section_items &&
                                              errors?.section_items[index]
                                                ?.item_name?.message}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                      <Col className="d-flex align-items-center">
                                        {item.item_id && (
                                          <Form.Group
                                            as={Row}
                                            className="w-100"
                                          >
                                            <Col className="d-flex align-items-center">
                                              <Form.Label className="me-2 mb-0">
                                                Section
                                              </Form.Label>

                                              <Form.Select
                                                name={`section_items[${index}].item_section`}
                                                {...register(
                                                  `section_items[${index}].item_section`
                                                )}
                                                onChange={(e) =>
                                                  handleMenuItemSectionChange(
                                                    item.item_id,
                                                    e
                                                  )
                                                }
                                              >
                                                <option
                                                  value=""
                                                  className="d-none"
                                                >
                                                  Select Section
                                                </option>
                                                {[...sections]
                                                  .filter(
                                                    (section) =>
                                                      section.section_name !==
                                                      "Uncategorized"
                                                  )
                                                  .map((section) => (
                                                    <option
                                                      value={section.section_id}
                                                      key={section.section_name}
                                                    >
                                                      {section.section_name}
                                                    </option>
                                                  ))}
                                              </Form.Select>
                                              <Form.Control.Feedback
                                                id="sectionNameError"
                                                type="invalid"
                                              >
                                                {!!errors?.section_items &&
                                                  errors?.section_items[index]
                                                    ?.item_section?.message}
                                              </Form.Control.Feedback>
                                            </Col>
                                          </Form.Group>
                                        )}
                                      </Col>
                                      <Col className="d-flex justify-content-end align-items-center">
                                        {!item.item_id && (
                                          <Button
                                            className="d-flex align-items-center me-1"
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={() =>
                                              addNewMenuItem(index)
                                            }
                                          >
                                            <MdOutlineSaveAlt className="me-2" />
                                            Save
                                          </Button>
                                        )}
                                        {item.item_id && (
                                          <>
                                            <Form.Check
                                              key={item.item_id}
                                              id={
                                                item.item_id +
                                                "" +
                                                item.is_verified
                                              }
                                              type="checkbox"
                                              label="Verified Item"
                                              checked={item.is_verified}
                                              onChange={() => verifyItem(index)}
                                              className="me-2"
                                            />

                                            <Button
                                              className="d-flex align-items-center me-2"
                                              variant="outline-secondary"
                                              size="sm"
                                              onClick={() =>
                                                duplicateMenuItem(index)
                                              }
                                            >
                                              <BiDuplicate className="me-2" />
                                              Duplicate
                                            </Button>
                                          </>
                                        )}
                                        <Button
                                          className="d-flex align-items-center"
                                          variant="outline-danger"
                                          size="sm"
                                          onClick={() => {
                                            item.item_id
                                              ? handleConfirmActionVisibility(
                                                  true,
                                                  index
                                                )
                                              : cancelNewMenuItemCreation(
                                                  index
                                                );
                                          }}
                                        >
                                          <RiDeleteBinLine className="me-2" />
                                          Delete
                                        </Button>
                                        {ableDragAndDrop && (
                                          <OverlayComponent
                                            index={index}
                                            list={fields}
                                            moveToTop={() =>
                                              handleChangePosition(index, 0)
                                            }
                                            moveToBottom={() =>
                                              handleChangePosition(
                                                index,
                                                fields.length - 1
                                              )
                                            }
                                          >
                                            <img
                                              {...provided.dragHandleProps}
                                              src={actionImage}
                                              className="action_drag ms-2"
                                              alt="action"
                                            />
                                          </OverlayComponent>
                                        )}
                                      </Col>
                                    </Row>
                                  </Card.Header>
                                  {item.item_id && index === activeKey && (
                                    <Container className="accordion-collapse">
                                      <Row>
                                        <Col>
                                          <Form.Group className="mt-2">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={8}
                                              name={`section_items[${index}].description`}
                                              {...register(
                                                `section_items[${index}].description`
                                              )}
                                              onBlur={(e) => {
                                                handleFieldOnBlurEvent(
                                                  index,
                                                  e?.target?.value,
                                                  "description"
                                                );
                                              }}
                                              isInvalid={
                                                !!errors?.section_items &&
                                                errors?.section_items[index]
                                                  ?.description
                                              }
                                            />
                                            <Form.Control.Feedback
                                              id="descriptionError"
                                              type="invalid"
                                            >
                                              {!!errors?.section_items &&
                                                errors?.section_items?.[index]
                                                  ?.description?.message}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group className="mt-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                              name={`section_items[${index}].price`}
                                              {...register(
                                                `section_items[${index}].price`
                                              )}
                                              onInput={(e) => {
                                                validate(e);
                                              }}
                                              onBlur={(e) => {
                                                if (isNaN(e.target.value)) {
                                                  return;
                                                }
                                                handleFieldOnBlurEvent(
                                                  index,
                                                  e.target.value
                                                    ? parseFloat(
                                                        e.target.value
                                                      ).toFixed(2)
                                                    : null,
                                                  "price"
                                                );
                                              }}
                                              isInvalid={
                                                !!errors?.section_items &&
                                                errors?.section_items?.[index]
                                                  ?.price
                                              }
                                            />
                                            <Form.Control.Feedback
                                              id="priceError"
                                              type="invalid"
                                            >
                                              {!!errors?.section_items &&
                                                errors?.section_items?.[index]
                                                  ?.price?.message}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                          <Ingredients
                                            getValues={getValues}
                                            control={control}
                                            restaurantId={restaurantId}
                                            register={register}
                                            errors={errors}
                                            ingredients={ingredients}
                                            itemIngredients={
                                              item.item_ingredients
                                            }
                                            menuIndex={index}
                                            isValid={isValid}
                                            updateMenuItem={updateMenuItem}
                                            setValue={setValue}
                                            updateMenuItemOnIngredientsChange={
                                              updateMenuItemOnIngredientsChange
                                            }
                                            reset={reset}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            ingredientsKey={ingredientsKey}
                                            ableDragAndDrop={ableDragAndDrop}
                                          />
                                          <Form.Group className="mt-3">
                                            <div className="d-flex align-items-center mb-3">
                                              <Form.Label className="mb-0">
                                                Allergens
                                              </Form.Label>
                                              <Button
                                                variant="outline-secondary"
                                                id="button-addon2"
                                                href={`${appConfigurations.API_BASE_URL}admin/menu/items/${item.item_id}/`}
                                                target="_blank"
                                                rel="noreferrer"
                                                size="sm"
                                                className="ms-2"
                                              >
                                                <HiOutlinePlusSm />
                                                Allergens
                                              </Button>
                                            </div>
                                            <div>
                                              {item?.allergens &&
                                              item?.allergens?.length > 0 ? (
                                                ingredientAllergenDietList(item)
                                                  .allergens.length > 0 ? (
                                                  ingredientAllergenDietList(
                                                    item
                                                  ).allergens.map((i, y) => (
                                                    <Badge
                                                      bg="primary"
                                                      className="me-2 mb-1"
                                                      key={y}
                                                    >
                                                      {i}
                                                    </Badge>
                                                  ))
                                                ) : (
                                                  <span>-</span>
                                                )
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </div>
                                          </Form.Group>
                                          <Form.Group className="mt-3">
                                            <Form.Label>Diets</Form.Label>
                                            <div>
                                              {item?.allergens &&
                                              item?.allergens?.length > 0 ? (
                                                ingredientAllergenDietList(item)
                                                  .diets.length > 0 ? (
                                                  ingredientAllergenDietList(
                                                    item
                                                  ).diets.map((i, y) => (
                                                    <Badge
                                                      bg="primary"
                                                      className="me-2 mb-1"
                                                      key={y}
                                                    >
                                                      {i}
                                                    </Badge>
                                                  ))
                                                ) : (
                                                  <span>-</span>
                                                )
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </div>
                                          </Form.Group>
                                          <Form.Group className="mt-3 mb-3">
                                            <Form.Label>
                                              Unused words
                                            </Form.Label>
                                            <div>
                                              {item?.unused_words &&
                                              item?.unused_words?.length > 0 ? (
                                                item?.unused_words
                                                  ?.split(",")
                                                  .map((i, y) => (
                                                    <Badge
                                                      bg="primary"
                                                      className="me-2 mb-1"
                                                      key={y}
                                                    >
                                                      {i}
                                                    </Badge>
                                                  ))
                                              ) : (
                                                <span>-</span>
                                              )}
                                            </div>
                                          </Form.Group>
                                        </Col>
                                        <Col>
                                          <ImageUploader
                                            getUploadedContent={(file) =>
                                              updateUploadedFiles(file, index)
                                            }
                                            removeUploadedContent={() =>
                                              removeUploadedFile(item, index)
                                            }
                                            initialImageData={
                                              fields[index].image
                                            }
                                            uploaderId={`${sectionIndex}_menuItem_${index}`}
                                            key={`${sectionIndex}_menuItem_${index}`}
                                          />
                                        </Col>
                                      </Row>
                                    </Container>
                                  )}
                                </Card>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Form>
          </div>
        </Accordion>
      )}
      {section?.section_id ? (
        <Row className="add-new-section mt-3 mb-3">
          <Col sm={5} className="d-flex align-items-center">
            <hr />
          </Col>
          <Col sm={2} className="d-flex justify-content-center">
            <Button
              type="button"
              variant="outline-primary"
              className="add-new-button"
              onClick={addNewMenuItemInput}
            >
              <HiOutlinePlusSm />
              Menu Item
            </Button>
          </Col>
          <Col sm={5} className="d-flex align-items-center">
            <hr />
          </Col>
        </Row>
      ) : null}
      <ConfirmAction
        show={confirmActionVisibility}
        confirmationText={"Are you sure you want to delete this Menu Item?"}
        confirmTitle={"Are you sure you want to delete?"}
        buttonVariant={"danger"}
        confirm={() => handleRemoveMenuItem(selectedMenuItemIndex)}
        confirmButtonText={"Delete"}
        cancelButtonText={"Cancel"}
        handleClose={() => handleConfirmActionVisibility(false)}
      />
    </>
  );
}
