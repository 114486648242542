import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

const OverlayComponent = ({
  children,
  moveToTop,
  moveToBottom,
  list,
  index,
  specificItems
}) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      rootClose
      overlay={
        <Popover>
          <Popover.Body className="p-2">
            <div className="d-grid gap-1">
              <Button
                variant="outline-primary"
                disabled={(specificItems ? index - specificItems : index) === 0}
                size="sm"
                onClick={moveToTop}
              >
                Move to top
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                disabled={index === list?.length - 1}
                onClick={moveToBottom}
              >
                Move to bottom
              </Button>
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default OverlayComponent;
