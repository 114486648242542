/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import {
  sortIngredientsByRestaurantId,
  sortIngredientsFollowKey
} from "../../../utilities/index";
import { get } from "../../../utilities/apiHelper";
import IngredientInfoModal from "../../../components/IngredientInfoModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import actionImage from "../../../action.svg";
import { arrayMoveImmutable } from "array-move";
import { toast } from "react-toastify";
import { sortOrderingOfSubstitutes } from "../../../store/menu/menuApi";
import { orderBy } from "lodash";
import OverlayComponent from "../../../components/OverlayComponent";
import { INGREDIENT_CAN_BE_REMOVED } from "../../../utilities/constants";

function Substitute(props) {
  const {
    substitutes,
    ingredients,
    selectedIngredients,
    register,
    errors,
    control,
    menuIndex,
    ingredientIndex,
    getValues,
    setError,
    restaurantId,
    updateMenuItemOnIngredientsChange,
    SelectedSubstitutesToCheckForIngredient,
    ableDragAndDrop
  } = props;

  const { venue } = useSelector((state) => state.venue);

  const [selectedSubstitutes, setSelectedSubstitutes] = useState(substitutes);
  const [listMenu, setListMenu] = useState([]);
  const [loadingIngredientInfo, setLoadingIngredientInfo] = useState();
  const [isShowIngredientInfo, setIsShowIngredientInfo] = useState(false);
  const [ingredientInfo, setIngredientInfo] = useState({
    title: "",
    description: ""
  });

  const { fields, remove, insert, replace } = useFieldArray({
    name: `section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes`,
    control,
    keyName: "key"
  });

  //Function to extract allergens of the substitute from backend response
  const ingredientAllergenList = (allergens) => {
    return allergens
      ?.filter((i) =>
        ["Allergens", "FODMAP Diets", "Diets"].includes(
          i.allergens_category?.category_name
        )
      )
      .map((a) => a.allergens_name);
  };

  //Add new substitute
  const addNewSubstitute = (index, substituteIndex) => {
    const values = getValues();
    const appendedSubstitute =
      values.section_items[menuIndex].item_ingredients[index].substitutes[
        substituteIndex
      ];

    if (appendedSubstitute && Object.keys(appendedSubstitute).length === 0) {
      setError(
        `section_items[${menuIndex}].item_ingredients[${index}].substitutes[${substituteIndex}]`,
        {
          type: "focus",
          message: "Substitute is a required field"
        },
        { shouldFocus: true }
      );
      return;
    }

    const substituteChangedIngredient =
      getValues().section_items?.[menuIndex].item_ingredients[index];
    substituteChangedIngredient.substitutes.push(appendedSubstitute);
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;

    ingredientList[index] = substituteChangedIngredient;
    updateMenuItemOnIngredientsChange(menuIndex, ingredientList);
    SelectedSubstitutesToCheckForIngredient(
      ingredientList.map((ing) => ing.substitutes)
    );
  };

  //Function to remove the new substitute inputs which are not actually saved in the database
  const cancelNewSubstitute = (substituteIndex) => {
    remove(substituteIndex);
  };

  //Function to remove substitute from ingredient
  const handleRemoveSubstitute = (index, substituteIndex) => {
    const ingredientList =
      getValues().section_items?.[menuIndex].item_ingredients;
    const substitutesList =
      getValues().section_items?.[menuIndex]?.item_ingredients[index]
        ?.substitutes;
    substitutesList.splice(substituteIndex, 1);
    ingredientList[index].substitutes = substitutesList;
    const ingredientsToBeSaved = ingredientList.filter(
      (ing) => ing.ingredient_id
    );
    updateMenuItemOnIngredientsChange(menuIndex, ingredientsToBeSaved);
    SelectedSubstitutesToCheckForIngredient(
      ingredientsToBeSaved.map((ing) => ing.substitutes)
    );
  };

  const handleSubstituteOnChangeEvent = (
    options,
    substituteIndex,
    substitute
  ) => {
    const currentSubstitutes = selectedSubstitutes;
    if (currentSubstitutes?.[substituteIndex]) {
      currentSubstitutes[substituteIndex] = options[0];
    } else currentSubstitutes.push(options[0]);

    setSelectedSubstitutes(currentSubstitutes);
    const isHideToast = true;
    if (substitute.substitute_id && options.length !== 0) {
      updateMenuItemOnIngredientsChange(
        menuIndex,
        getValues().section_items?.[menuIndex].item_ingredients?.filter(
          (ing) => ing.ingredient_id
        ),
        isHideToast
      );
    }
    addNewSubstitute(ingredientIndex, substituteIndex);
  };

  //Function change reorder list menu
  const handleSearch = (query, index) => {
    const keySearch = query.toLowerCase();
    const menus = [...filterOption(ingredients, index)].filter(
      (menu) =>
        (!menu.restaurant_id ||
          menu.restaurant_id == restaurantId ||
          menu.restaurant_id == venue?.parent_restaurant ||
          (venue?.list_child_restaurant?.length &&
            venue?.list_child_restaurant.includes(menu.restaurant_id))) &&
        (menu?.display_name?.toLowerCase().includes(keySearch) ||
          menu?.name?.toLowerCase().includes(keySearch))
    );

    const formatList = sortIngredientsByRestaurantId(
      menus,
      restaurantId,
      venue?.parent_restaurant
    );

    const mainItems = formatList.filter((one) => !!one.display_name);
    const other = formatList.filter((one) => !one.display_name);

    const newList = [
      ...sortIngredientsFollowKey(keySearch, mainItems, "display_name"),
      ...sortIngredientsFollowKey(keySearch, other, "name")
    ];

    const idxIngredientCanBeRemove = newList.findIndex(
      (one) => one.name === INGREDIENT_CAN_BE_REMOVED
    );
    if (idxIngredientCanBeRemove !== -1) {
      setListMenu(arrayMoveImmutable(newList, idxIngredientCanBeRemove, 0));
    } else {
      setListMenu(newList);
    }
  };

  const handleClick = (index) => {
    const menus = filterOption(ingredients, index);
    setListMenu(
      menus.filter(
        (menu) => !menu.restaurant_id || menu.restaurant_id == restaurantId
      )
    );
  };

  const convertIgredientName = (ingredient) => {
    if (ingredient.display_name) {
      return `${ingredient.display_name} (${ingredient.name})`;
    }

    return ingredient.name;
  };

  const filterOption = (list, index) => {
    return [...list].filter(
      (ing) =>
        ing?.id !==
          selectedIngredients?.[ingredientIndex]?.substitute_ingredient?.id &&
        !selectedSubstitutes
          ?.map((sub) => sub?.id)
          .filter(
            (id) =>
              !(
                selectedSubstitutes[index] &&
                selectedSubstitutes[index]?.id === id
              )
          )
          .includes(ing?.id)
    );
  };

  const viewMoreIngredient = async (result) => {
    setLoadingIngredientInfo(true);
    setIsShowIngredientInfo(true);
    try {
      const title = result.display_name ?? result.name;
      const res = await get(
        `/menu/v10/get_allergens_by_ingredient/?id=${result.id}`,
        true
      );
      if (res.data.length) {
        setIngredientInfo({
          description: res.data.join(", "),
          title
        });
      } else {
        setIngredientInfo({ title });
      }
    } catch (error) {
      alert(error.message);
      setIsShowIngredientInfo(false);
    } finally {
      setLoadingIngredientInfo(false);
    }
  };

  const checkRestaurantRelation = (restaurant_id) => {
    if (!restaurant_id) return false;
    return (
      restaurant_id != restaurantId &&
      venue?.parent_restaurant != restaurant_id &&
      !venue?.list_child_restaurant?.includes(restaurant_id)
    );
  };

  const handleChangePosition = async (startIndex, endIndex) => {
    try {
      const payload = {
        ingredient_id:
          getValues().section_items?.[menuIndex].item_ingredients[
            ingredientIndex
          ].ingredient_id,
        substitutes: arrayMoveImmutable(fields, startIndex, endIndex).map(
          ({ sub_id }, index) => ({ sub_id, order: index + 1 })
        )
      };
      remove(startIndex);
      insert(endIndex, fields[startIndex]);
      await sortOrderingOfSubstitutes(payload);
      toast.success("Menu item updated successfully");
    } catch (error) {
      toast.error("Failed to update the Menu item");
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex !== endIndex) {
      handleChangePosition(startIndex, endIndex);
    }
  };

  useEffect(() => {
    replace(orderBy(substitutes, ["order"]));
  }, [substitutes]);

  return (
    <>
      {fields && fields?.length > 0 && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields?.map((substitute, substituteIndex) => (
                    <Draggable
                      key={substitute.key}
                      isDragDisabled={!ableDragAndDrop}
                      draggableId={substitute.key}
                      index={substituteIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="ps-4">
                            <div className="substitute">
                              <hr className="tree-line" />
                              <Form.Group>
                                <div className="substitute-box">
                                  <Controller
                                    control={control}
                                    name={`section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`}
                                    {...register(
                                      `section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`
                                    )}
                                    render={({
                                      field,
                                      fieldState: {
                                        isInvalid,
                                        isTouched,
                                        isDirty,
                                        error
                                      }
                                    }) => {
                                      const { display_name, name, restaurant } =
                                        field?.value;
                                      const isError =
                                        checkRestaurantRelation(restaurant);
                                      return (
                                        <div
                                          className={`input-box ${
                                            isError && "error-box"
                                          }`}
                                        >
                                          <div className="d-flex align-items-center">
                                            <InputGroup className="position-relative">
                                              <Typeahead
                                                key={substitute.id}
                                                placeholder="Select Substitute"
                                                id={`section_items[${menuIndex}].item_ingredients[${ingredientIndex}].substitutes[${substituteIndex}]`}
                                                labelKey={({
                                                  display_name,
                                                  name
                                                }) => {
                                                  return !!display_name
                                                    ? `${display_name} (${name})`
                                                    : name;
                                                }}
                                                multiple={false}
                                                className="substitute-input-field"
                                                onChange={(options) => {
                                                  field.onChange(options);
                                                  handleSubstituteOnChangeEvent(
                                                    options,
                                                    substituteIndex,
                                                    substitute
                                                  );
                                                }}
                                                onInputChange={(query) =>
                                                  handleSearch(
                                                    query,
                                                    substituteIndex
                                                  )
                                                }
                                                defaultInputValue={
                                                  display_name ?? name
                                                }
                                                onFocus={(event) =>
                                                  handleSearch(
                                                    event.target.value,
                                                    substituteIndex
                                                  )
                                                }
                                                options={listMenu}
                                                renderMenu={(
                                                  results,
                                                  menuProps
                                                ) => (
                                                  <Menu {...menuProps}>
                                                    {results.map(
                                                      (result, index) => {
                                                        return (
                                                          <MenuItem
                                                            style={{
                                                              position:
                                                                "relative",
                                                              padding:
                                                                "6px 20px",
                                                              color:
                                                                result.is_official
                                                                  ? "#0747A6"
                                                                  : "black",
                                                              fontWeight:
                                                                result.is_official
                                                                  ? "bold"
                                                                  : "400"
                                                            }}
                                                            option={result}
                                                            position={index}
                                                          >
                                                            {convertIgredientName(
                                                              result
                                                            )}
                                                            <span
                                                              className="ingredient-more-info"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                viewMoreIngredient(
                                                                  result
                                                                );
                                                              }}
                                                            >
                                                              &#9432;
                                                            </span>
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Menu>
                                                )}
                                              />
                                              <div className="edit-icon">
                                                <BiEditAlt />
                                              </div>
                                            </InputGroup>
                                            <Button
                                              className="d-flex align-items-center ms-2"
                                              variant="outline-danger"
                                              size="sm"
                                              onClick={() => {
                                                substitute?.substitute_id
                                                  ? handleRemoveSubstitute(
                                                      ingredientIndex,
                                                      substituteIndex
                                                    )
                                                  : cancelNewSubstitute(
                                                      substituteIndex
                                                    );
                                              }}
                                            >
                                              <RiDeleteBinLine className="me-2" />
                                              Delete
                                            </Button>
                                            {ableDragAndDrop && (
                                              <OverlayComponent
                                                index={substituteIndex}
                                                list={fields}
                                                moveToTop={() =>
                                                  handleChangePosition(
                                                    substituteIndex,
                                                    0
                                                  )
                                                }
                                                moveToBottom={() =>
                                                  handleChangePosition(
                                                    substituteIndex,
                                                    fields.length - 1
                                                  )
                                                }
                                              >
                                                <img
                                                  src={actionImage}
                                                  {...provided.dragHandleProps}
                                                  className="action_drag ms-2"
                                                  alt="action"
                                                />
                                              </OverlayComponent>
                                            )}
                                          </div>
                                          {(isInvalid ||
                                            isTouched ||
                                            isDirty ||
                                            error) && (
                                            <div className="invalid-custom-feedback">
                                              {
                                                errors?.section_items?.[
                                                  menuIndex
                                                ]?.item_ingredients?.[
                                                  ingredientIndex
                                                ]?.substitutes?.[
                                                  substituteIndex
                                                ]?.message
                                              }
                                            </div>
                                          )}
                                          {!!substitute.allergens &&
                                            substitute.allergens.length !=
                                              0 && (
                                              <div className="substitute-allergens">
                                                <p>
                                                  {ingredientAllergenList(
                                                    substitute?.allergens
                                                  ).join(", ")}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <IngredientInfoModal
            isOpen={isShowIngredientInfo}
            isLoading={loadingIngredientInfo}
            onClose={() => setIsShowIngredientInfo(false)}
            title={`${ingredientInfo.title} - Allergens`}
            description={ingredientInfo.description}
            emptyLabel="No Allergens"
          />
        </>
      )}
    </>
  );
}
export default forwardRef(Substitute);
