import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { BsImage } from "react-icons/bs";
import LogoDefault from "../images/logo-default.png";

const ImageUploader = ({
  getUploadedContent,
  initialImageData,
  uploaderId,
  removeUploadedContent
}) => {
  const [fileUploaderKey, setFileUploaderKey] = useState(
    uploaderId + Math.random()
  );
  const [fileDataURL, setFileDataURL] = useState(initialImageData);
  const isDisableDeleteButton =
    fileDataURL && fileDataURL.includes("default_food.jpg");

  useEffect(() => {
    setFileDataURL(`${initialImageData}&version=${Date.now()}`);
  }, [initialImageData]);

  const handleFileSelected = (e) => {
    const picture = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const { result } = e.target;
      if (result) {
        setFileDataURL(result);
      }
    };
    fileReader.readAsDataURL(picture);

    const formData = new FormData();
    formData.append("image", picture, picture.name);
    getUploadedContent(picture);
  };

  const removeFile = (e) => {
    setFileUploaderKey(uploaderId + Math.random());
    setFileDataURL(null);
    removeUploadedContent();
    getUploadedContent(null);
  };

  const renderPreview = () => {
    return (
      <img
        src={fileDataURL ?? LogoDefault}
        alt={fileDataURL}
        onError={(error) => {
          error.target.src = LogoDefault;
        }}
      />
    );
  };

  return (
    <>
      <div className="image-upload">
        {!fileDataURL && <BsImage className="placeholder-image" />}
        <input
          key={fileUploaderKey}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleFileSelected}
          id={uploaderId}
          style={{ display: "none" }}
        />
        <label
          className="btn btn-outline-secondary choose-file btn-sm"
          htmlFor={uploaderId}
        >
          Choose File
        </label>
        <Button
          variant="danger"
          className="ms-3 delete-button"
          size="sm"
          onClick={() => removeFile()}
          disabled={!fileDataURL || isDisableDeleteButton}
        >
          <RiDeleteBin6Line />
        </Button>
        <div className="mt-3 image-preview">{renderPreview()}</div>
      </div>
    </>
  );
};

export default ImageUploader;
