import { useEffect, useState } from "react";

const BEFORE_UNLOAD_EVENT = "beforeunload";

const useSaveFormData = (formState) => {
  const { dirtyFields, isValidating } = formState;
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const unloadListener = (event) => {
      if (isChanged) {
        event.preventDefault();
        event.returnValue = true;
        return "";
      }
    };
    window.addEventListener(BEFORE_UNLOAD_EVENT, unloadListener);
    return () => {
      window.removeEventListener(BEFORE_UNLOAD_EVENT, unloadListener);
    };
  }, [isChanged]);

  useEffect(() => {
    const isFormDirty = Object.keys(dirtyFields).length;
    setIsChanged(isFormDirty);
  }, [dirtyFields, isValidating]);
};

export default useSaveFormData;
