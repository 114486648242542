import { appConfigurations } from "./config";
import axios from "axios";
import history from "../utilities/history";
import { toast } from "react-toastify";
import { NETWORK_MESSAGE } from "./constants";

const MAX_RETRIES = 5;
const ERR_NETWORK = "ERR_NETWORK";

axios.defaults.baseURL = `${appConfigurations.API_BASE_URL}`;

const unhandledError = {
  message: "Connection failed. Please try again.",
  code: 500
};

export async function putMultiData(url, data, authenticationRequired = false) {
  let options = {
    data,
    url,
    method: "put",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeadersForMultipartData(
    options,
    authenticationRequired,
    token
  );
  return axios(options);
}

export async function put(url, data, authenticationRequired = false) {
  let options = {
    data,
    url,
    method: "put",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeaders(options, authenticationRequired, token);
  return axios(options);
}

export async function patch(url, data, authenticationRequired = false) {
  let options = {
    data,
    url,
    method: "patch",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeaders(options, authenticationRequired, token);
  return axios(options);
}

export async function patchMultiData(
  url,
  data,
  authenticationRequired = false
) {
  let options = {
    data,
    url,
    method: "patch",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeadersForMultipartData(
    options,
    authenticationRequired,
    token
  );
  return axios(options);
}

export async function post(url, data, authenticationRequired = false) {
  let options = {
    data,
    url,
    method: "post",
    responseType: "json"
  };

  const token = getToken();

  options = await addRequestHeaders(options, authenticationRequired, token);
  return axios(options);
}

export async function get(url, authenticationRequired = false) {
  let options = {
    url,
    method: "get",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeaders(options, authenticationRequired, token);
  return axios(options);
}

export async function remove(url, authenticationRequired = false) {
  let options = {
    url,
    method: "delete",
    responseType: "json"
  };

  const token = getToken();
  options = await addRequestHeaders(options, authenticationRequired, token);
  return axios(options);
}

async function addRequestHeaders(options, authenticationRequired, token) {
  options.headers = {};
  if (authenticationRequired) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return options;
}

async function addRequestHeadersForMultipartData(
  options,
  authenticationRequired,
  token
) {
  options.headers = {
    "Content-Type": "multipart/form-data"
  };
  if (authenticationRequired) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  return options;
}

export function getToken() {
  const sessionDetails = JSON.parse(localStorage.getItem("currentSessionInfo"));
  return sessionDetails?.access;
}

// Response interceptors for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.code === ERR_NETWORK) {
      const retryCount = (error.config.retryCount || 0) + 1;
      error.config.retryCount = retryCount;
      if (retryCount <= MAX_RETRIES) {
        return new Promise((resolve, _) => {
          setTimeout(() => {
            resolve(axios(error.config));
          }, 2 * retryCount * 1000);
        });
      }
    } else {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.config.url !== "/auth/user/login/"
      ) {
        const restaurantId = history.location.pathname
          .split("/")[1]
          .split("/")[0];
        history.push(`/${restaurantId}/login`);
      } else if (error.response && error.response.status === 500) {
        throw unhandledError;
      } else {
        throw error.response ? error?.response?.data?.message?.detail : error;
      }
    }
  }
);

const handleNetworkChange = () => {
  const options = {
    position: "bottom-left",
    icon: true
  };
  if (window.navigator.onLine) {
    toast.success(NETWORK_MESSAGE.ONLINE, options);
  } else {
    toast.error(NETWORK_MESSAGE.OFFLINE, options);
  }
};

window.addEventListener("online", handleNetworkChange);
window.addEventListener("offline", handleNetworkChange);
