export const LoadingState = {
  idle: "idle",
  loading: "loading",
  succeeded: "succeeded",
  failed: "failed"
};

export const INGREDIENT_CAN_BE_REMOVED = "Ingredient Can Be Removed";

export const NETWORK_MESSAGE = {
  ONLINE: "The internet connection has been re-established.",
  OFFLINE: "You're offline. Check your connection."
};
